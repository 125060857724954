/* tslint:disable */
/* eslint-disable */
/**
 * Probonio full API Docs
 * If you stumbled upon this page and want to integrate Probonio into your business software [click here!](/api/external)    If you\'re looking for more information about Probonio [head over here](https://probonio.de)    **This documentation describes all Endpoints of the Probonio API. It\'s created for experienced Probonio Developers only!**  
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ReceiptDTO
 */
export interface ReceiptDTO {
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'status': ReceiptDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'lunchReceiptType'?: ReceiptDTOLunchReceiptTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'mobilityType'?: ReceiptDTOMobilityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'mobilityTravelPurpose'?: ReceiptDTOMobilityTravelPurposeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'tenantId': string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'employeeId': string;
    /**
     * 
     * @type {number}
     * @memberof ReceiptDTO
     */
    'total': number;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'rejectionReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'imageURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'pdfURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'mobilityValidityStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'mobilityValidityEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'ocrDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'ocrPostalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'receiptLabels'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'ocrAmount'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReceiptDTO
     */
    'receiptCred'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReceiptDTO
     */
    'rejectionDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReceiptDTO
     */
    'rejectionCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReceiptDTO
     */
    'isAuditedByTenant'?: boolean;
}

export const ReceiptDTOStatusEnum = {
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Pending: 'PENDING'
} as const;

export type ReceiptDTOStatusEnum = typeof ReceiptDTOStatusEnum[keyof typeof ReceiptDTOStatusEnum];
export const ReceiptDTOLunchReceiptTypeEnum = {
    Single: 'SINGLE',
    Group: 'GROUP',
    Mixed: 'MIXED'
} as const;

export type ReceiptDTOLunchReceiptTypeEnum = typeof ReceiptDTOLunchReceiptTypeEnum[keyof typeof ReceiptDTOLunchReceiptTypeEnum];
export const ReceiptDTOMobilityTypeEnum = {
    LocalTrain: 'LOCAL_TRAIN',
    LongDistanceTrain: 'LONG_DISTANCE_TRAIN',
    LocalBus: 'LOCAL_BUS',
    LongDistanceBus: 'LONG_DISTANCE_BUS',
    Car: 'CAR',
    SharingOffers: 'SHARING_OFFERS',
    Taxi: 'TAXI',
    Bike: 'BIKE',
    TimeCard: 'TIME_CARD',
    DeutschlandTicket: 'DEUTSCHLAND_TICKET',
    Others: 'OTHERS'
} as const;

export type ReceiptDTOMobilityTypeEnum = typeof ReceiptDTOMobilityTypeEnum[keyof typeof ReceiptDTOMobilityTypeEnum];
export const ReceiptDTOMobilityTravelPurposeEnum = {
    Work: 'WORK',
    Private: 'PRIVATE'
} as const;

export type ReceiptDTOMobilityTravelPurposeEnum = typeof ReceiptDTOMobilityTravelPurposeEnum[keyof typeof ReceiptDTOMobilityTravelPurposeEnum];


