import { Alert, AlertTitle, Box, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { apis } from 'probonio-shared-ui/module/api';
import { useWithMessage } from 'probonio-shared-ui/module/error';
import { useTenant, useTenantID } from 'probonio-shared-ui/module/me';
import { validateEmail } from 'probonio-shared-ui/utils/email';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DialogState } from '../../../component/dialog';
import { DialogLoadingButton } from '../../../component/dialog/DialogLoadingButton';
import { DatePickerControl } from '../../../component/form';
import { CheckboxControl } from '../../../component/form/CheckboxControl';
import TextFieldChipsControl from '../../../component/form/TextFieldChipsControl';
import { fontWeights } from '../../../theme';
import { ApplyTemplateFormValues, SelectTemplateControl } from '../../tenant/benefitTemplates/SelectTemplateControl';
import { useBenefitTemplates } from '../../tenant/benefitTemplates/benefitTemplateQueries';
import { useRefetchEmployees } from '../query';
import { useTemplateMinStartDate } from '../../tenant/benefitTemplates/useTemplateMinStartDate';
import { isTemplateValid } from '../../tenant/benefitTemplates/validateTemplate';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
  dialogState: DialogState;
}

interface AddUserFields extends ApplyTemplateFormValues {
  emails: string[];
  sendMail: boolean;
}

class DuplicateMailError extends Error {
  public constructor(public mails: string[]) {
    super();
  }
}

export const AddUserMail: React.FC<Props> = ({ dialogState }) => {
  const { t } = useTranslation('usersModule');
  const getTenantId = useTenantID();
  const { tenant } = useTenant();
  const { enqueueSnackbar } = useSnackbar();
  const withMessage = useWithMessage();
  const [failedEmails, setFailedEmails] = useState<string[]>([]);
  const refetchEmployees = useRefetchEmployees();
  const formMethods = useForm<AddUserFields>({
    defaultValues: {
      emails: [],
      templateId: '',
      startDate: DateTime.now().startOf('month').toJSDate(),
    },
    mode: 'onChange',

    resolver: values => {
      const emails = values.emails;
      for (const email of emails) {
        if (!validateEmail(email)) {
          return {
            values,
            errors: {
              emails: { type: 'pattern', message: t('createNewUserFields.notValidMail') },
            },
          };
        }
      }
      return {
        values: { emails: emails.map(email => email.toLowerCase()) },
        errors: {},
      };
    },
  });
  const { control, reset, handleSubmit, getValues, watch, setValue, formState } = formMethods;
  const sendMail = watch('sendMail');
  const templateId = watch('templateId');
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const minStartDate = useTemplateMinStartDate(templateId, dialogState.isOpen);

  useEffect(() => {
    if (DateTime.fromJSDate(startDate) < minStartDate) {
      setValue('startDate', minStartDate.toJSDate());
    }
    if (!endDate) return;
    if (DateTime.fromJSDate(startDate).endOf('month') > DateTime.fromJSDate(endDate).endOf('month')) {
      setValue('endDate', startDate);
    }
  }, [startDate, endDate, setValue, minStartDate]);

  useEffect(() => {
    reset();
    setFailedEmails([]);
  }, [dialogState.isOpen, reset]);

  const { data: templates } = useBenefitTemplates({ enabled: dialogState.isOpen });
  useEffect(() => {
    const defaultTemplate = templates?.results.find(template => template.isDefault);
    if (defaultTemplate && isTemplateValid(tenant, defaultTemplate)) {
      setValue('templateId', defaultTemplate.id);
    }
  }, [setValue, templates, tenant]);

  const addUserMutation = useMutation({
    mutationFn: async (values: AddUserFields) => {
      const duplicates: string[] = [];
      for (const mail of values.emails) {
        try {
          const { id } = await apis.employee
            .createEmployee({ tenantId: getTenantId(), createEmployeeDTO: { mail }, sendMail })
            .then(res => res.data);
          if (templateId) {
            await apis.benefits.applyBenefitTemplate({
              tenantId: getTenantId(),
              employeeId: id,
              applyBenefitTemplateDTO: {
                startDate: DateTime.fromJSDate(values.startDate).toFormat('yyyy-MM'),
                endDate: values.endDate && DateTime.fromJSDate(values.endDate).toFormat('yyyy-MM'),
                benefitTemplateId: values.templateId,
              },
            });
          }
        } catch (err: unknown) {
          if ((err as AxiosError).response?.status === 409) {
            duplicates.push(mail);
          } else {
            throw err;
          }
        }
      }
      if (duplicates.length > 0) {
        throw new DuplicateMailError(duplicates);
      }
    },

    onSuccess: async () => {
      await refetchEmployees();
      enqueueSnackbar(t('usersPage:success'), { variant: 'success' });
      dialogState.handleClose();
      setFailedEmails([]);
    },
    onError: async err => {
      if (err instanceof DuplicateMailError) {
        setFailedEmails(err.mails);
        await refetchEmployees();
      } else {
        withMessage(err as Error);
      }
    },
  });

  const handleAddUser = useCallback(() => {
    if (addUserMutation.isPending) {
      return;
    }
    const mails = getValues('emails');
    if (mails.length < 1) {
      return;
    }
    addUserMutation.mutate(getValues());
  }, [addUserMutation, getValues]);

  return (
    <Box>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleAddUser)}>
          {failedEmails.length > 0 && (
            <Alert sx={{ marginBottom: 2 }} severity="error">
              <AlertTitle>{t('createNewUserFields.duplicatedMails')}</AlertTitle>
              {failedEmails.map(email => (
                <div key={email}>{email}</div>
              ))}
            </Alert>
          )}
          <Typography color="text.secondary" fontSize={14} fontWeight={fontWeights.semiBold}>
            {t('createNewUserFields.putEmail')}
          </Typography>
          <Typography color="text.secondary" fontSize={14} marginTop={2} fontWeight={fontWeights.semiBold}>
            {t('createNewUserFields.helperEmail')}
          </Typography>
          <TextFieldChipsControl
            data-test-id="user-button-email-add"
            sx={{ marginTop: 2 }}
            label={t('createNewUserFields.emailAddress')}
            fullWidth
            control={control}
            name="emails"
            disabled={addUserMutation.isPending}
          />
          <Grid
            container
            flexGrow={1}
            marginTop={1}
            alignItems="center"
            sx={{ '.MuiFormControlLabel-label': { color: 'text.secondary', typography: 'body2' } }}
          >
            <Grid>
              <CheckboxControl control={control} name="sendMail" label={t('createNewUserFields.sendMail')} />
            </Grid>
            <Grid height="100%" alignItems="center">
              <Tooltip title={t('createNewUserFields.sendMailTooltip')} tabIndex={0}>
                <InfoOutlinedIcon color="action" fontSize="small" sx={{ marginLeft: -0.5, marginY: -0.4 }} />
              </Tooltip>
            </Grid>
          </Grid>
          <Typography color="text.secondary" fontSize={14} fontWeight={fontWeights.semiBold} marginTop={1} gutterBottom>
            {t('createNewUserFields.templateHelper')}
          </Typography>
          <Typography color="text.secondary" fontSize={14} fontWeight={fontWeights.semiBold}>
            <Trans i18nKey="usersModule:userForm.applyTemplate.infoManage">
              <Link to="/settings/benefitTemplates" />
            </Trans>
          </Typography>
          {templates?.results.length ? (
            <Stack marginTop={2} marginBottom={2} spacing={2}>
              <SelectTemplateControl
                control={control}
                name="templateId"
                noSelectionOption
                label={t('createNewUserFields.template')}
                fullWidth
              />
              <Stack direction="row" spacing={1}>
                <DatePickerControl
                  control={control}
                  name="startDate"
                  label={t('benefitsModule:fields.dateFrom')}
                  fullWidth
                  margin="dense"
                  monthYearPicker
                  minDate={minStartDate.toJSDate()}
                  maxDate={DateTime.now().plus({ years: 10 }).toJSDate()}
                  rules={{ required: !!templateId }}
                />
                <DatePickerControl
                  control={control}
                  name="endDate"
                  label={t('benefitsModule:fields.dateTill')}
                  fullWidth
                  margin="dense"
                  monthYearPicker
                  minDate={minStartDate.toJSDate()}
                  maxDate={DateTime.now().plus({ years: 10 }).toJSDate()}
                />
              </Stack>
            </Stack>
          ) : undefined}
        </form>
      </FormProvider>
      <Box display="flex" justifyContent="right" data-test-id="user-button-add">
        <DialogLoadingButton
          loading={addUserMutation.isPending}
          onClick={handleSubmit(handleAddUser)}
          disabled={!formState.isValid}
          variant="text"
        >
          {t(`createNewUserFields.${sendMail ? 'inviteButton' : 'createButton'}`)}
        </DialogLoadingButton>
      </Box>
    </Box>
  );
};
